import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import Link from 'gatsby-link';
import styled from 'styled-components';
import Layout from 'components/layout';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { useSpring, animated, config } from 'react-spring';
import linkResolver from 'utils/linkResolver';

const styles = {
  button: {
    border: '1px solid white',
  },
  link: {
    display: 'block',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'background-color 0.3s ease-in-out',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
};

export const StyledWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 500px;
  overflow: hidden;
`;

export const Slide = styled(animated.div)`
  height: 100vh;
  width: 100vw;
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
`;

const Image = ({ url, duration }) => {
  const props = useSpring({
    from: {
      transform: 'scale(1) translateX(0%)',
    },
    to: {
      transform: 'scale(1.2) translateX(0%)',
    },
    config: {
      duration: duration - 200,
    },
  });
  return <Slide style={{ ...props }} image={url} />;
};

Image.propTypes = {
  url: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
};

function Landing(props) {
  const { data, classes } = props;

  useEffect(() => {
    navigate('work-with-us');
  }, []);

  const doc = data.prismic.allLanding_pages.edges.slice(0, 1).pop();
  if (!doc) return null;
  return <div></div>;
  /*return (
    <Layout footer={false} hideMenu={true}>
      <StyledWrapper>
        <Image url={doc.node.image.url} duration={15000} />
        {doc.node.links && (
          <Container maxWidth="xl" style={{ height: '100%' }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              position="relative"
              style={{ height: '100%', zIndex: 10 }}
              pb={10}
            >
              <Grid container spacing={5}>
                {doc.node.links.map((item, key) => {
                  return (
                    <Grid item xs={12} md={6} key={key}>
                      <Box className={classes.button}>
                        <Box
                          component={Link}
                          py={4}
                          to={item.link ? linkResolver(item.link._meta) : ''}
                          className={classes.link}
                        >
                          <Typography
                            component="span"
                            variant="h4"
                            color="secondary"
                          >
                            {item.link_text[0].text}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Container>
        )}
      </StyledWrapper>
    </Layout>
  );*/
}

export default withStyles(styles)(Landing);

Landing.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export const query = graphql`
  query LandingPageQuery {
    prismic {
      allLanding_pages {
        edges {
          node {
            image
            links {
              link {
                ... on PRISMIC_Homepage {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Standard_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
              }
              link_text
            }
          }
        }
      }
    }
  }
`;
