import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Layout from 'components/layout';
import SmallImageHero from 'components/hero/SmallImageHero';
import { RichText } from 'prismic-reactjs';
import { withStyles } from '@material-ui/styles';
import { Tag, Share } from 'components/ui';
import { Helmet } from 'react-helmet';

const styles = theme => ({
  body: {
    textTransform: 'none',
    letterSpacing: '0.75px',
  },
  date: {
    borderBottom: `1px solid ${theme.palette.background.border}`,
  },
});

function Article(props) {
  const { classes } = props;
  const doc = props.data.prismic.allArticles.edges.slice(0, 1).pop();
  if (!doc) return null;
  const getDate = () => {
    var date;

    doc.node.display_publish_date
      ? (date = doc.node.display_publish_date)
      : (date = doc.node._meta.firstPublicationDate);

    var ret = new Date(date);
    const day = ret.getDate();
    const month = ret.toLocaleString('default', { month: 'long' });
    const year = ret.getFullYear();
    return month + ' ' + day + ', ' + year;
  };

  return (
    <Layout
      headerMenuType={
        doc.node.header_menu_type ? doc.node.header_menu_type : false
      }
    >
      {doc.node.metadata && doc.node.metadata[0] && (
        <Helmet>
          {doc.node.metadata[0].title && doc.node.metadata[0].title[0] && (
            <title>{doc.node.metadata[0].title[0].text}</title>
          )}
          {doc.node.metadata[0].title && doc.node.metadata[0].title[0] && (
            <meta
              content={doc.node.metadata[0].title[0].text}
              property="og:title"
            />
          )}
          {doc.node.metadata[0].title && doc.node.metadata[0].title[0] && (
            <meta
              content={doc.node.metadata[0].title[0].text}
              name="twitter:title"
            />
          )}
          {doc.node.metadata[0].description &&
            doc.node.metadata[0].description[0] && (
              <meta
                name="description"
                content={doc.node.metadata[0].description[0].text}
              />
            )}
          {doc.node.metadata[0].description &&
            doc.node.metadata[0].description[0] && (
              <meta
                property="og:description"
                content={doc.node.metadata[0].description[0].text}
              />
            )}
          {doc.node.metadata[0].description &&
            doc.node.metadata[0].description[0] && (
              <meta
                name="twitter:description"
                content={doc.node.metadata[0].description[0].text}
              />
            )}
          {doc.node.metadata[0].keywords &&
            doc.node.metadata[0].keywords[0] && (
              <meta
                name="keywords"
                content={doc.node.metadata[0].keywords[0].text}
              />
            )}
          {doc.node.metadata[0].social_share_image &&
            doc.node.metadata[0].social_share_image.url && (
              <meta
                content={`${doc.node.metadata[0].social_share_image.url}&q=10&lossless=1&crop=center&w=1024&h=512`}
                property="og:image"
              />
            )}
          {doc.node.metadata[0].social_share_image &&
            doc.node.metadata[0].social_share_image.url && (
              <meta
                content={`${doc.node.metadata[0].social_share_image.url}&q=10&lossless=1&crop=center&w=1024&h=512`}
                name="twitter:image"
              />
            )}
        </Helmet>
      )}
      <SmallImageHero />
      <Container maxWidth="xl">
        <Box py={10}>
          {doc.node.content_tagging.length > 0 &&
            doc.node.content_tagging[0].tag && (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="row"
                mb={4}
              >
                {doc.node.content_tagging.map((item, key) => {
                  if (item) {
                    return <Tag data={item.tag[0].text} key={key} />;
                  }
                })}
              </Box>
            )}
          <Typography component="h1" variant="h3" align="center">
            {doc.node.content_title[0].text}
          </Typography>
        </Box>
      </Container>
      {doc.node.content_image && doc.node.content_image?.url && (
        <Container maxWidth="xl">
          <Box>
            <img
              src={doc.node.content_image.url}
              alt={doc.node.content_image.alt}
            />
          </Box>
        </Container>
      )}
      <Container maxWidth="md">
        <Box pt={5}>
          <Box className={classes.date} pb={4} mb={8}>
            <Typography component="p" variant="subtitle1">
              {getDate()}
            </Typography>
          </Box>
          <Typography
            component="div"
            variant="subtitle1"
            className={classes.body}
          >
            <RichText render={doc.node.body} />
          </Typography>
        </Box>
        {doc.node.show_share_options && (
          <Box>
            <Share
              url={doc.node._meta.uid}
              title={doc.node.content_title[0].text}
            />
          </Box>
        )}
      </Container>
    </Layout>
  );
}
export const query = graphql`
  query ArticlePageQuery($uid: String) {
    prismic {
      allArticles(uid: $uid) {
        edges {
          node {
            _meta {
              id
              uid
              type
              firstPublicationDate
            }
            metadata {
              title
              keywords
              description
              social_share_image
            }
            header_menu_type
            banner_image
            banner_title
            content_title
            body
            content_image
            content_tagging {
              tag
            }
            show_share_options
            display_publish_date
          }
        }
      }
    }
  }
`;

Article.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Article);
