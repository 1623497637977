import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { ButtonBlockDark } from 'components/ui';
import linkResolver from 'utils/linkResolver';
import trimString from 'helpers/trimString';

const styles = theme => ({
  wrapper: {
    border: `1px solid ${theme.palette.background.border}`,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    fontSize: '1rem',
    letterSpacing: '0.75px',
    marginBottom: '30px',
  },
  divider: {
    height: '1px',
    width: '60%',
    background: theme.palette.background.border,
  },
  inner: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

function Card(props) {
  const { data, classes } = props;
  const item = data;
  return (
    <Box className={classes.wrapper}>
      <Box>
        <img src={item.image.url} alt={item.image.alt} />
      </Box>
      <Box p={4} className={classes.inner}>
        <Typography component="h6" variant="h6" align="center">
          {item.title[0].text}
        </Typography>
        <Box className={classes.divider} my={4} mx="auto" />
        {item.text && (
          <Typography
            component="h6"
            variant="subtitle2"
            align="center"
            className={classes.text}
          >
            {trimString(item.text[0].text, 200)}
          </Typography>
        )}
        <Box mt="auto" display="flex" justifyContent="center">
          <ButtonBlockDark text="Learn More" link={linkResolver(item._meta)} />
        </Box>
      </Box>
    </Box>
  );
}

Card.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Card);
