import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Layout from 'components/layout';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ImageHero from 'components/hero/imagehero';
import {
  ImageSlider,
  TitleText,
  HouseTypeSlideshow,
  TitleCaptionText,
  ImageGrid,
  Downloads,
  Location,
  Availability,
  RegisterInterest,
  SchemeDetails,
  FullImage,
  Video,
  IframeEmbed,
} from 'components/slices';
import Spacer from 'components/spacer';
import { withStyles } from '@material-ui/styles';
import Logo from 'images/LivingSpace-Wordmark.svg';
import { Helmet } from 'react-helmet';
import linkResolver from 'utils/linkResolver';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

// Query for the Blog Post content in Prismic
export const query = graphql`
  query DevelopmentPageQuery($uid: String) {
    prismic {
      allDevelopments(uid: $uid) {
        edges {
          node {
            _meta {
              id
              uid
              type
            }
            metadata {
              description
              keywords
              title
            }
            title
            telephone
            email
            opening_hours
            additional_info
            geolocation
            banner_image
            body {
              ... on PRISMIC_DevelopmentBodyIframe_embed {
                type
                label
                primary {
                  url
                }
              }
            }
            address {
              address_line_1
              address_line_2
              county
              postcode
              town
              local_area_guide {
                ... on PRISMIC__FileLink {
                  url
                }
              }
            }
            introduction
            prices_from
            brochure {
              ... on PRISMIC__FileLink {
                url
                name
              }
            }
            promotion_banners {
              image
            }
            main_page_slideshow {
              image
              title
            }
            video_url
            available_house_types {
              home_type {
                ... on PRISMIC_Home_type {
                  title
                  _meta {
                    uid
                    type
                    id
                  }
                  number_of_bedrooms
                  thumbnail_image
                }
              }
            }
            the_details {
              text
              title
            }
            details_images {
              tile1_image
              tile2_image
              tile3_image
              tile4_image
              tile5_image
              tile6_image
              tile7_image
            }
            download_block_content {
              image
              text
              title
            }
            downloads {
              download_item {
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                }
              }
              title
            }
            location_details {
              caption
              text
              title
            }
            location_images {
              title
              image
            }
            available_house_types {
              home_type {
                ... on PRISMIC_Home_type {
                  title
                  price_from
                  number_of_bedrooms
                  homes_available
                  available_plots
                }
              }
            }
            scheme_details {
              title
              logo_image
              image
              link {
                ... on PRISMIC_Basic_page {
                  _meta {
                    uid
                    type
                    id
                  }
                }
                ... on PRISMIC_Article {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Standard_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const styles = theme => ({
  logo: {
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      width: '100px',
    },
  },
  btnLink: {
    display: 'inline-block',
    cursor: 'pointer',
    border: `3px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    color: theme.palette.text.white,
    padding: '5px 20px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.text.white}`,
    transition: 'box-shadow 0.2s ease-in-out',
    [theme.breakpoints.up('md')]: {
      padding: '8px 20px',
    },
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.text.white}`,
    },
  },
});

const Development = props => {
  const { classes } = props;
  const doc = props.data.prismic.allDevelopments.edges.slice(0, 1).pop();
  if (!doc) return null;
  console.log(doc);
  return (
    <Layout developmentPageMenu={doc.node._meta}>
      {doc.node.metadata && doc.node.metadata[0] && (
        <Helmet>
          {doc.node.metadata[0].title && doc.node.metadata[0].title[0] && (
            <title>{doc.node.metadata[0].title[0].text}</title>
          )}
          {doc.node.metadata[0].description &&
            doc.node.metadata[0].description[0] && (
              <Helmet>
                <meta
                  name="description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  property="og:description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  name="twitter:description"
                  content={doc.node.metadata[0].description[0].text}
                />
              </Helmet>
            )}
          {doc.node.metadata[0].keywords &&
            doc.node.metadata[0].keywords[0] && (
              <meta
                name="keywords"
                content={doc.node.metadata[0].keywords[0].text}
              />
            )}
        </Helmet>
      )}
      <ImageHero
        data={doc.node.banner_image}
        title={doc.node.title[0].text}
        brochure={doc.node.brochure ? doc.node.brochure.url : ''}
        email={doc.node.email ? doc.node.email[0].text : ''}
        telephone={doc.node.telephone ? doc.node.telephone[0].text : ''}
        opening_hours={
          doc.node.opening_hours ? doc.node.opening_hours[0].text : ''
        }
        additional_info={
          doc.node.additional_info ? doc.node.additional_info[0].text : ''
        }
        contactbar
      />
      <Container maxWidth="xl">
        <Spacer>
          <Grid container>
            <Box
              component={Grid}
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              mb={[4, 4, 0]}
            >
              <Logo className={classes.logo} />
              <Box py={3}>
                <Typography component="h2" variant="h2">
                  {doc.node.title[0].text}
                </Typography>
              </Box>
              <Typography component="p" variant="subtitle1">
                {doc.node.address
                  ? doc.node.address[0].address_line_1
                    ? doc.node.address[0].address_line_1[0].text
                    : null
                  : null}
                ,{' '}
                {doc.node.address
                  ? doc.node.address[0].address_line_2
                    ? doc.node.address[0].address_line_2[0].text
                    : null
                  : null}
              </Typography>
              <Typography component="p" variant="subtitle1">
                {doc.node.address[0].town[0].text},{' '}
                {doc.node.address[0].county[0].text},{' '}
                {doc.node.address[0].postcode[0].text}
              </Typography>
            </Box>
            <Box component={Grid} item xs={12} md={5}>
              <Typography component="p" variant="subtitle1" gutterBottom>
                {doc.node.introduction[0].text}
              </Typography>
              {doc.node.prices_from &&
                doc.node.prices_from[0] &&
                doc.node.prices_from[0].text && (
                  <Box pt={2}>
                    <Typography component="p" variant="h6">
                      Prices from {doc.node.prices_from[0].text}
                    </Typography>
                    {doc.node.available_house_types && (
                      <Box mt={2}>
                        <AnchorLink
                          to={`${linkResolver(doc.node._meta)}#availability`}
                          title="Availability"
                          className={`${classes.btnLink} stripped`}
                          stripHash
                        >
                          <Typography component="span" variant="button">
                            View Availability
                          </Typography>
                        </AnchorLink>
                      </Box>
                    )}
                  </Box>
                )}
            </Box>
          </Grid>
        </Spacer>
        {doc.node.promotion_banners &&
          doc.node.promotion_banners[0] &&
          doc.node.promotion_banners[0].image && (
            <React.Fragment>
              {doc.node.promotion_banners.map((item, key) => (
                <Spacer key={key}>
                  <FullImage data={item} />
                </Spacer>
              ))}
            </React.Fragment>
          )}
        {doc.node.main_page_slideshow && doc.node.main_page_slideshow[0] && (
          <Spacer>
            <ImageSlider data={doc.node.main_page_slideshow} />
          </Spacer>
        )}

        {doc.node.body && (
          <React.Fragment>
            {doc.node.body.map((item, i) => {
              switch (item.type) {
                case 'iframe_embed':
                  return (
                    <Spacer key={i}>
                      <IframeEmbed data={item.primary} />
                    </Spacer>
                  );
                default:
                  return null;
              }
            })}
          </React.Fragment>
        )}

        {doc.node.the_details && doc.node.the_details[0].text && (
          <Spacer>
            <TitleText data={doc.node.the_details[0]} />
            {doc.node.details_images[0].tile1_image && (
              <Spacer>
                <ImageGrid data={doc.node.details_images} />
              </Spacer>
            )}
          </Spacer>
        )}
      </Container>
      {doc.node.available_house_types &&
        doc.node.available_house_types[0] &&
        doc.node.available_house_types[0].home_type && (
          <Box id="housetypes">
            <Spacer>
              <HouseTypeSlideshow data={doc.node.available_house_types} />
            </Spacer>
          </Box>
        )}
      <Container maxWidth="xl">
        {doc.node.downloads &&
          doc.node.downloads[0] &&
          doc.node.downloads[0].title && (
            <Box id="siteplan">
              <Spacer>
                <Downloads
                  data={doc.node.downloads}
                  content={doc.node.download_block_content[0]}
                />
              </Spacer>
            </Box>
          )}
        {doc.node.location_details &&
          doc.node.location_details[0] &&
          doc.node.location_details[0].title && (
            <Spacer>
              <TitleCaptionText data={doc.node.location_details[0]} />
            </Spacer>
          )}
        {doc.node.location_images && (
          <Spacer>
            <ImageSlider data={doc.node.location_images} />
          </Spacer>
        )}
        {doc.node.video_url && doc.node.video_url.embed_url && (
          <Spacer>
            <Video data={doc.node} />
          </Spacer>
        )}
        {doc.node.address && doc.node.geolocation && (
          <Spacer>
            <Location
              title={doc.node.title[0].text}
              data={doc.node.address[0]}
              geolocation={doc.node.geolocation}
              locationPostcode={doc.node.address[0].postcode[0].text}
              localareaguide={
                doc.node.address[0].local_area_guide
                  ? doc.node.address[0].local_area_guide.url
                  : ''
              }
            />
          </Spacer>
        )}
      </Container>
      <Container maxWidth="lg">
        {doc.node.available_house_types &&
          doc.node.available_house_types[0] &&
          doc.node.available_house_types[0].home_type && (
            <Box id="availability">
              <Spacer>
                <Availability data={doc.node.available_house_types} />
              </Spacer>
            </Box>
          )}
        <Spacer>
          <RegisterInterest
            telephone={
              doc.node.telephone
                ? doc.node.telephone[0].text
                : process.env.DEFAULT_TELEPHONE
            }
            email={
              doc.node.email
                ? doc.node.email[0].text
                : process.env.DEFAULT_EMAIL
            }
          />
        </Spacer>
        {doc.node.scheme_details.length > 0 &&
          doc.node.scheme_details[0] &&
          doc.node.scheme_details[0].title && (
            <Spacer>
              <SchemeDetails data={doc.node.scheme_details} />
            </Spacer>
          )}
      </Container>
    </Layout>
  );
};

Development.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Development);
