import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import Layout from 'components/layout';
import ImageHero from 'components/hero/imagehero';
import SmallImageHero from 'components/hero/SmallImageHero';
import {
  TitleCaptionText,
  TextLeftImageRight,
  InlineImageGrid,
  LargeImageBlock,
  ImageRightTextLeft,
  FounderQuote,
  TitleCaptionTwoColumnText,
  V2TextLeftImageRight,
  TeamMembers,
  WidescreenImageBlock,
  ImageSlider,
  TwoColumnText,
  FourImageGrid,
  Testimonials,
  Offers,
  Articles,
  Careers,
  ThreeColumns,
  Projects,
  Image,
} from 'components/slices';
import Spacer from 'components/spacer';
import { Helmet } from 'react-helmet';

function Page(props) {
  const doc = props.data.prismic.allStandard_pages.edges.slice(0, 1).pop();
  if (!doc) return null;
  return (
    <Layout
      headerMenuType={
        doc.node.header_menu_type ? doc.node.header_menu_type : false
      }
    >
      {doc.node.metadata && doc.node.metadata[0] && (
        <Helmet>
          {doc.node.metadata[0].title && doc.node.metadata[0].title[0] && (
            <title>{doc.node.metadata[0].title[0].text}</title>
          )}
          {doc.node.metadata[0].description &&
            doc.node.metadata[0].description[0] && (
              <Helmet>
                <meta
                  name="description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  property="og:description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  name="twitter:description"
                  content={doc.node.metadata[0].description[0].text}
                />
              </Helmet>
            )}
          {doc.node.metadata[0].keywords &&
            doc.node.metadata[0].keywords[0] && (
              <meta
                name="keywords"
                content={doc.node.metadata[0].keywords[0].text}
              />
            )}
        </Helmet>
      )}
      {!doc.node.header_hero_type && (
        <ImageHero
          data={doc.node.banner_image}
          title={doc.node.title[0].text}
          logo={doc.node.show_hayfield_logo_in_banner}
          email={
            doc.node.banner_bottom_bar && doc.node.banner_bottom_bar[0].email
              ? doc.node.banner_bottom_bar[0].email[0].text
              : ''
          }
          telephone={
            doc.node.banner_bottom_bar &&
            doc.node.banner_bottom_bar[0].telephone
              ? doc.node.banner_bottom_bar[0].telephone[0].text
              : ''
          }
          opening_hours={
            doc.node.banner_bottom_bar &&
            doc.node.banner_bottom_bar[0].opening_hours
              ? doc.node.banner_bottom_bar[0].opening_hours[0].text
              : ''
          }
          external_link={
            doc.node.banner_bottom_bar && doc.node.banner_bottom_bar[0].link
              ? doc.node.banner_bottom_bar[0].link.url
              : ''
          }
          contactbar={
            doc.node.banner_bottom_bar &&
            doc.node.banner_bottom_bar[0].show_info_bar_in_banner === true
              ? true
              : false
          }
          bannerImageOverlayColor={doc.node.banner_image_overlay_color}
          bannerImageOverlayOpacity={doc.node.banner_image_overlay_opacity}
        />
      )}
      {doc.node.header_hero_type && <SmallImageHero />}
      {doc.node.body && (
        <React.Fragment>
          {doc.node.body.map((item, i) => {
            switch (item.type) {
              case 'title__caption__text':
                return (
                  <Spacer key={i}>
                    <Container maxWidth="xl">
                      <TitleCaptionText data={item.primary} />
                    </Container>
                  </Spacer>
                );
              case 'text_left_image_right':
                return (
                  <Spacer key={i}>
                    <TextLeftImageRight data={item.primary} />
                  </Spacer>
                );
              case 'inline_image_grid':
                return (
                  <Spacer key={i}>
                    <InlineImageGrid
                      primary={item.primary}
                      data={item.fields}
                    />
                  </Spacer>
                );
              case 'large_image_block':
                return (
                  <Spacer key={i}>
                    <LargeImageBlock data={item.primary} />
                  </Spacer>
                );
              case 'image_left_text_right':
                return (
                  <Spacer key={i}>
                    <ImageRightTextLeft primary={item.primary} />
                  </Spacer>
                );
              case 'founder_quote':
                return (
                  <Spacer key={i}>
                    <FounderQuote data={item.primary} />
                  </Spacer>
                );
              case 'title__caption__two_column_text':
                return (
                  <Spacer key={i}>
                    <Container maxWidth="xl">
                      <TitleCaptionTwoColumnText data={item.primary} />
                    </Container>
                  </Spacer>
                );
              case 'v2_text_left__image_right':
                return (
                  <Spacer key={i}>
                    <V2TextLeftImageRight data={item.primary} />
                  </Spacer>
                );
              case 'team_members':
                return (
                  <Spacer key={i}>
                    <TeamMembers data={item.primary} fields={item.fields} />
                  </Spacer>
                );
              case 'widescreen_image_block':
                return (
                  <Spacer key={i}>
                    <WidescreenImageBlock
                      data={item.primary}
                      fields={item.fields}
                    />
                  </Spacer>
                );
              case 'image_slideshow':
                return (
                  <Spacer key={i}>
                    <Container maxWidth="xl">
                      <ImageSlider data={item.fields} />
                    </Container>
                  </Spacer>
                );
              case 'two_column_text':
                return (
                  <Spacer key={i}>
                    <TwoColumnText data={item.primary} />
                  </Spacer>
                );
              case '4_image_grid':
                return (
                  <Spacer key={i}>
                    <FourImageGrid data={item.primary} />
                  </Spacer>
                );
              case 'testimonials':
                return (
                  <Spacer key={i}>
                    <Testimonials data={item.primary} fields={item.fields} />
                  </Spacer>
                );
              case 'offers':
                return (
                  <Spacer key={i}>
                    <Offers data={item.fields} />
                  </Spacer>
                );
              case 'articles':
                return (
                  <Spacer key={i}>
                    <Articles data={item.fields} />
                  </Spacer>
                );
              case 'career_opportunities_':
                return (
                  <Spacer key={i}>
                    <Careers data={item.primary} fields={item.fields} />
                  </Spacer>
                );
              case 'three_column':
                return (
                  <Spacer key={i}>
                    <ThreeColumns data={item.primary} fields={item.fields} />
                  </Spacer>
                );
              case 'projects':
                return (
                  <Spacer key={i}>
                    <Projects data={item.primary} fields={item.fields} />
                  </Spacer>
                );
              case 'image':
                return (
                  <Spacer key={i}>
                    <Container maxWidth="xl">
                      <Image data={item.primary} />
                    </Container>
                  </Spacer>
                );
              default:
                return null;
            }
          })}
        </React.Fragment>
      )}
    </Layout>
  );
}

export const query = graphql`
  query StandardTypePageQuery($uid: String) {
    prismic {
      allStandard_pages(uid: $uid) {
        edges {
          node {
            _meta {
              id
              uid
              type
            }
            metadata {
              description
              keywords
              title
            }
            header_menu_type
            title
            header_hero_type
            banner_image_overlay_color
            banner_image_overlay_opacity
            banner_image
            body {
              ... on PRISMIC_Standard_pageBodyTitle__caption__text {
                type
                label
                primary {
                  show_hayfield_logo
                  title
                  caption
                  text
                }
              }
              ... on PRISMIC_Standard_pageBodyText_left_image_right {
                type
                label
                primary {
                  caption
                  text
                  title
                  align_text
                  image
                }
              }
              ... on PRISMIC_Standard_pageBodyInline_image_grid {
                type
                label
                primary {
                  title
                }
                fields {
                  image
                }
              }
              ... on PRISMIC_Standard_pageBodyLarge_image_block {
                type
                label
                primary {
                  link_title
                  show_logo_ident
                  show_logo_text
                  show_social_icons
                  title
                  image
                  link {
                    ... on PRISMIC_Standard_page {
                      _meta {
                        id
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Contact_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Basic_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodyImage_left_text_right {
                type
                label
                primary {
                  image_title
                  image_link_title
                  image_link {
                    ... on PRISMIC_Standard_page {
                      title
                      _meta {
                        uid
                        type
                        id
                      }
                    }
                  }
                  image
                  title
                  caption
                  text
                }
              }
              ... on PRISMIC_Standard_pageBodyFounder_quote {
                type
                label
                primary {
                  author
                  job_title
                  image
                  mobile_image
                  signature_image
                  text
                  title
                }
              }
              ... on PRISMIC_Standard_pageBodyTitle__caption__two_column_text {
                type
                label
                primary {
                  show_hayfield_logo
                  title
                  caption
                  text_left_column
                  text_right_column
                }
              }
              ... on PRISMIC_Standard_pageBodyV2_text_left__image_right {
                type
                label
                primary {
                  title
                  text
                  image
                  link_title
                  link {
                    ... on PRISMIC_Standard_page {
                      _meta {
                        uid
                        type
                        tags
                        id
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodyTeam_members {
                type
                label
                primary {
                  title
                }
                fields {
                  job_role
                  credentials
                  name
                  email
                  image
                }
              }
              ... on PRISMIC_Standard_pageBodyWidescreen_image_block {
                type
                fields {
                  link_title
                  link {
                    ... on PRISMIC_Standard_page {
                      title
                      _meta {
                        uid
                        type
                        id
                      }
                    }
                  }
                }
                primary {
                  title
                  image
                }
              }
              ... on PRISMIC_Standard_pageBodyImage_slideshow {
                type
                fields {
                  title
                  image
                }
              }
              ... on PRISMIC_Standard_pageBodyTwo_column_text {
                type
                primary {
                  text_first_column
                  text_second_column
                }
              }
              ... on PRISMIC_Standard_pageBody4_image_grid {
                type
                primary {
                  tile1_image
                  tile2_image
                  tile3_image
                  tile4_image
                }
              }
              ... on PRISMIC_Standard_pageBodyTestimonials {
                type
                primary {
                  title
                  caption
                }
                fields {
                  customer_name
                  date
                  testimonial
                }
              }
              ... on PRISMIC_Standard_pageBodyOffers {
                type
                label
                fields {
                  offer {
                    _linkType
                    ... on PRISMIC_Basic_page {
                      _meta {
                        uid
                        type
                        id
                      }
                      title
                      text
                      image
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodyArticles {
                type
                label
                fields {
                  article {
                    _linkType
                    ... on PRISMIC_Article {
                      _meta {
                        uid
                        type
                        id
                      }
                      title
                      text
                      image
                      content_tagging {
                        tag
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodyCareer_opportunities_ {
                type
                label
                primary {
                  title
                }
                fields {
                  department_and_location
                  job_description
                  job_title
                  link {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodySearch_block {
                type
                label
                primary {
                  image
                  title
                }
              }
              ... on PRISMIC_Standard_pageBodyThree_column {
                type
                label
                primary {
                  title
                  caption
                }
                fields {
                  caption
                  image
                  title
                  link {
                    ... on PRISMIC_Home_type {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Article {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Standard_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Basic_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Homepage {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Development {
                      title
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Contact_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodyProjects {
                type
                label
                primary {
                  title
                  caption
                }
                fields {
                  project {
                    ... on PRISMIC_Project {
                      title
                      thumbnail_image
                      _meta {
                        uid
                        type
                        id
                      }
                      address {
                        town
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_Standard_pageBodyImage {
                type
                primary {
                  image
                }
              }
            }
          }
        }
      }
    }
  }
`;

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;
