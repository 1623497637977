import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import SearchBar from 'components/search';

const Background = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  width: 90vw;
`;

function FrontHeroMobile(props) {
  const { title, image } = props;
  return (
    <Box position="relative">
      <Background style={{ backgroundImage: `url(${image.url})` }}>
        <Title>
          <Typography
            component="h1"
            variant="h1"
            color="secondary"
            align="center"
          >
            {title}
          </Typography>
        </Title>
      </Background>
    </Box>
  );
}

FrontHeroMobile.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default FrontHeroMobile;
